.hero-landing {
  position: relative;
  z-index: 10;
}

.hero-landing__container {
  position: relative;
}

.hero-landing__background {
  position: absolute;
  width: 100%;
  height: 370px;

  &::after {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba(color(black), 0.7);
  }

  @include breakpoint(large) {
    height: 550px;
  }
}

.hero-landing__background-image {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 370px;
  object-fit: cover;

  @include breakpoint(large) {
    height: 550px;
  }
}

.hero-landing__header-container {
  @include create-row;
  align-items: center;
  z-index: 1;
  height: 370px;
  overflow: hidden;

  @include breakpoint(large) {
    height: 550px;
  }
}

.hero-landing__heading-inner {
  @include flex-grid-column(10);
  @include grid-column-offset(1);
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  @include breakpoint(large) {
    @include flex-grid-size(7);
    @include grid-column-offset(1);
  }
}

.hero-landing__header-icon-wrapper {
  width: 34px;
  height: 50px;
  position: absolute;
  transform: translate(-48px, 0px);

  @include breakpoint(medium) {
    transform: translate(-54px);
  }
}

.hero-landing__header-icon-line {
  position: absolute;
  width: 1px;
  height: 500px;
  background-color: color(red);
  transform: translate(24px, 70px);

  @include breakpoint(medium) {
    transform: translate(30px, 70px);
  }

  @include breakpoint(large) {
    transform: translate(10px, 70px);
  }
}

.hero-landing__header-icon {
  width: 30px;
  height: 50px;
  position: absolute;
  transform: translate(9px);

  @include breakpoint(medium) {
    transform: translate(15px);
  }

  @include breakpoint(large) {
    transform: translate(-7px);
    width: 36px;
  }
}

.hero-landing__header-icon-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  transform: translate(-43px);

  @include breakpoint(large) {
    width: 100%;
    transform: translate(-58px);
  }
}

.hero-landing__header-icon-inner {
  position: relative;
}

.hero-landing__heading {
  @include text-heading-1;
  color: color(white);
  margin-top: space(-2.75);

  @include breakpoint(large) {
    margin-top: space(-5.5);
  }
}

.hero-landing__content-container {
  @include create-row;
  background-color: color(white);
}

.hero-landing__content-inner {
  @include flex-grid-column(10);
  @include grid-column-offset(1);
  padding-bottom: space(7.5);

  @include breakpoint(medium) {
    @include grid-column-offset(1);
  }

  @include breakpoint(large) {
    @include flex-grid-size(7);
    //@include grid-column-offset(1);
    padding-top: space(17.5);
    padding-bottom: space(17.5);
  }
}

.hero-landing__content-wrapper {
  position: relative;
}

.hero-landing__content-icon-inner {
  width: 36px;
  height: calc(100% + 68px);
  position: absolute;
  bottom: 0;
  transform: translate(-60px);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint(medium) {
    transform: translate(-61px, -2px);
  }

  @include breakpoint(large) {
    overflow: hidden;
  }
}

.hero-landing__content-icon-line {
  position: absolute;
  bottom: 15px;
  width: 1px;
  // height: 500px;
  height:100%;
  background-color: color(red);
  transform: translate(19px, -1px);

  @include breakpoint(medium) {
    //transform: translate(34px, -1px);
  }

  @include breakpoint(large) {
    transform: none;
  }
}

.hero-landing__content-icon {
  position: absolute;
  bottom: 0;
  height: 15px;
  width: 15px;
  order: 1;
  transform: translate(19px, -1px);

  @include breakpoint(medium) {
    //transform: translate(34px, -1px);
  }

  @include breakpoint(large) {
    transform: none;
  }
}

@media all and (-ms-high-contrast: none) {
  .hero-landing__content-icon {
    transform: translateX(-8px);
  }
}

.hero-landing__copy {
  @include text-body-large;
  padding-bottom: space(7.5);

  @include breakpoint(large) {
    padding-bottom: space(10);
  }
}

.hero-landing__cta {
  .base-cta-primary {
    margin-top: 0;
  }
}

.hero-landing__quicklinks {
  @include flex-grid-column(12);
  transform: translate(0, -25px);
  order: -1;
  z-index: 2;

  @include breakpoint(large) {
    order: 1;
    @include flex-grid-size(3);
    @include grid-column-offset(1);
    transform: translate(0, -40px);
  }

  a:focus {
     outline: 2px dotted #000;
     outline-offset: 4px;
  }
}

.hero-landing__quicklinks-inner {
  background-color: color(gold);
  padding: 30px;
}

.hero-landing__quicklinks-header {
  @include text-heading-7;
  color: color(red);
  text-transform: uppercase;
  padding-bottom: space(5);
}

.hero-landing__quicklink-text {
  @include text-body-small;
  font-weight: 500;
  padding-bottom: space(4);
}

.hero-landing__video-button {
  position: absolute;
  right: 35px;
  bottom: 35px;
  z-index: 1;
  width: 36px;
  height: 36px;
  padding: space(2);
  border: 2px solid color(white);
  border-radius: 50%;
  background-color: rgba(color(black), 0.4);
  fill: color(white);

  @include breakpoint(large) {
    right: 50px;
    bottom: 50px;
    width: 36px;
    height: 36px;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid color(transparent);
    border-radius: 50%;
    @include transition(border-color);
    content: "";
  }

  &:hover::before {
    border-color: color(white);
  }

  &:focus {
    @include focus-white;
  }
}

.hero-landing__video-button-icon--pause {
  width: 100%;
  display: none;

  .hero-landing__video-button.isPlaying & {
    display: block;
  }
}

.hero-landing__video-button-icon--play {
  width: 100%;
  display: block;

  .hero-landing__video-button.isPlaying & {
    display: none;
  }
}

.hero-primary__video {
  position: absolute;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  // IE10+ CSS here
  .hero-landing__header-icon-line {
    position: absolute;
    left: 0;
  }

  .hero-landing__header-icon-container {
    position: static;
  }
}

// 2022 landing hero updates
.hero-landing__heading-inner {
  align-items:flex-end;
}

.hero-landing .hero-landing__background:after {
  background-color:transparent;
  background: linear-gradient(0deg, rgba(0,0,0,0.6) 22%, rgba(0,0,0,0.4) 44%, rgba(0,0,0,0) 64%);
}

.hero-landing__background,
.hero-landing__background-image,
.hero-landing__header-container {
  height:370px;

  @include breakpoint(medium) {
    height:400px;
  }

  @include breakpoint(large) {
    height:450px;
  }
}

.hero-landing__subheading {
  color:color(red);
  font-family:font(proxima-nova);
  font-weight:700;
  letter-spacing: rem-calc(0.7);
  line-height:rem(28); // 28px
  font-size:rem(20); // 20px

  @include breakpoint(medium) {
    line-height:rem(36); // 36px
    font-size:rem(26); // 26px
  }
}

.hero-landing__heading {
  font-size:rem(42); // 42px
  line-height:rem(48); // 48px

  @include breakpoint(medium) {
    font-size:rem(60); // 60px
    line-height:rem(68); // 68px
  }

  @include breakpoint(large) {
    font-size:rem(60); // 60px
    line-height:rem(68); // 68px
  }
}

.hero-landing__heading-container {
  margin-bottom:space(12); // 3em

  @include breakpoint(medium) {
    margin-bottom:space(16); // 4em
  }

  @include breakpoint(large) {
    margin-bottom:space(8); // 2em
  }
}

.hero-landing__content-icon-line {
	 height:100%;

   @include breakpoint(large) {
     height:90%;
   }
}

.hero-landing__content-inner {

  @include breakpoint(large) {
    padding-top:30px;
  }
}
